<template>
  <base-material-card
    class="v-card--material-chart"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template v-slot:heading>
      <div
        class="chartjs-wrapper col-lg-12"
        style="text-align: center; padding: unset; margin: 6px 0;"
      >
        <canvas
          ref="chartjsradar"
          class="chartjs-radar"
        />
      </div>
    </template>

    <slot
      slot="reveal-actions"
      name="reveal-actions"
    />

    <slot>
      <h4 class="card-title font-weight-light mt-2 ml-2">
        Distribuzione Campionario
      </h4>

      <p class="d-inline-flex font-weight-light ml-2 mt-1">
        Indice Budget {{ annocorrente }}
      </p>
    </slot>

    <slot
      slot="actions"
      name="actions"
    />
  </base-material-card>
</template>

<script>
  import Chart from 'chart.js'
  export default {
    name: 'TrgRadarChart',
    props: {
      datasets: {
        type: Array,
        required: true,
      },
    },
    data () {
      return {
        chart: null,
        annocorrente: 2020,
      }
    },
    watch: {
      datasets (newDatasets) {
        // Replace the datasets and call the update() method on Chart.js
        // instance to re-render the chart.
        console.log('updrdr ', newDatasets)
        this.chart.data.datasets = newDatasets
        this.chart.update()
      },
    },
    created () {

    },
    mounted () {
      this.chart = new Chart(this.$refs.chartjsradar, {
        type: 'radar',
        data: {
          labels: [['Tavoli', 'Sedie'], ['Letti'], ['Complementi'], 'Campionatura'],
          datasets: this.datasets,
        },
        options: {
          legend: {
            display: false,
            labels: {
              fontColor: 'rgb(255, 99, 132)',
            },
          },
          scale: {
            ticks: {
              display: true,
              beginAtZero: true,
              fontSize: 10,
              backgroundColor: 'rgba(255, 255, 255, 0.8)',
            },
          },
        },
      })
    },
    beforeDestroy () {
      // Don't forget to destroy the Chart.js instance.
      if (this.chart) {
        this.chart.destroy()
      }
    },
    methods: {

    },
  }
</script>

<style lang="sass">
  .v-card--material-chart
    p
      color: #999

    .v-card--material__heading
      max-height: none

      .ct-label
        color: inherit
        opacity: .7
        font-size: 0.975rem
        font-weight: 100

      .ct-grid
        stroke: rgba(255, 255, 255, 0.2)

      .ct-series-a .ct-point,
      .ct-series-a .ct-line,
      .ct-series-a .ct-bar,
      .ct-series-a .ct-slice-donut
          stroke: rgba(255,255,255,.8)

      .ct-series-a .ct-slice-pie,
      .ct-series-a .ct-area
          fill: rgba(255,255,255,.4)
</style>
