import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'

Vue.use(Router)

const router = new Router({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      component: () => import('@/views/layout/LoginLayout'),
      children: [
        // login
        {
          name: 'Login',
          path: '',
          component: () => import('@/views/login/Login'),
        },
        {
          name: 'Logout',
          path: 'logout',
          component: () => import('@/views/login/Logout'),
        },
      ],
    },
    {
      path: '/view',
      component: () => import('@/views/layout/DashboardLayout'),
      children: [
        // Dashboard
        {
          name: 'Dashboard',
          path: '',
          component: () => import('@/views/dashboard/Dashboard'),
        },
        // Dashboard
        {
          name: 'Dashboards',
          path: 'dashboard',
          component: () => import('@/views/dashboard/Dashboard'),
        },
        // Pages
        {
          name: 'Cleinti',
          path: 'pages/clienti',
          component: () => import('@/views/dashboard/pages/Clienti'),
        },
        {
          name: 'User Profile',
          path: 'pages/user',
          component: () => import('@/views/dashboard/pages/UserProfile'),
        },
        {
          name: 'Notifications',
          path: 'components/notifications',
          component: () => import('@/views/dashboard/component/Notifications'),
        },
        {
          name: 'Icons',
          path: 'components/icons',
          component: () => import('@/views/dashboard/component/Icons'),
        },
        {
          name: 'Typography',
          path: 'components/typography',
          component: () => import('@/views/dashboard/component/Typography'),
        },
        // Tables
        {
          name: 'Regular Tables',
          path: 'tables/regular-tables',
          component: () => import('@/views/dashboard/tables/RegularTables'),
        },
        // Maps
        {
          name: 'Google Maps',
          path: 'maps/google-maps',
          component: () => import('@/views/dashboard/maps/GoogleMaps'),
        },
        // Upgrade
        {
          name: 'Upgrade',
          path: 'upgrade',
          component: () => import('@/views/dashboard/Upgrade'),
        },
      ],
    },
    {
      name: 'PageNotFound',
      path: '*',
      component: () => import('@/views/login/PageNotFound'),
    },
  ],
})

router.beforeEach((to, from, next) => {
  console.log('controllo autenticazione', store)
  console.log(store.getters.isAuthenticated)

  console.log('sto arrivando da ' + from.path)
  console.log('Sto andando a ' + to.path)

  if (!store.getters.isAuthenticated) {
      if (to.path !== '/') {
          console.log('vado a login')
          next('/')
      } else {
          next() // se non sto andando alla login va alla login. quindi se sto andando alla login va avanti (alla login)
      }
  }

  if (store.getters.isAuthenticated) {
      if (to.path === '/') {
          next('/view')
      } else {
          next()
      }
  }
})

export default router
