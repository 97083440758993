<template>
  <div style=" background-color:#FFF; box-shadow:inset 0px -3px 3px 0px #9e9e9e; border-radius:3px; padding: 5px 0">
    <canvas
      id="chartjsbar"
      ref="chartjsbar"
      class="chartjsbar"
    />
  </div>
</template>

<script>
  import Chart from 'chart.js'

  export default {
    name: 'TrgBarChart',
    props: {
      // The chart's data.datasets
      datasets: {
        type: Object,
        required: true,
      },
    },
    data () {
      return {
        chart: null,
        options: {
          title: {
            display: false,
            text: 'Rapporto fatturato/budget',
          },
          // scales: {
          //   xAxes: [{
          //     ticks: {
          //       autoSkip: false,
          //       maxRotation: 90,
          //       minRotation: 90,
          //     },
          //   }],
          // },
          legend: { display: false },
        },
      }
    },
    watch: {
      data (newDatasets) {
        // Replace the datasets and call the update() method on Chart.js
        // instance to re-render the chart.
        console.log('updBar ', newDatasets)
        this.chart.data.datasets = newDatasets
        this.chart.update()
      },
    },
    mounted () {
      console.log('mount_data', this.datasets)
      var c = document.getElementById('chartjsbar')
      var ctx = c.getContext('2d')
      ctx.rotate(20 * Math.PI / 180)
      this.chart = new Chart(this.$refs.chartjsbar, {
        type: 'bar',
        data: this.datasets,
        options: this.options,
      })
    },
    beforeDestroy () {
      // Don't forget to destroy the Chart.js instance.
      if (this.chart) {
        this.chart.destroy()
      }
    },
  }
</script>
