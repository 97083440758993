<template>
  <base-material-card
    class="v-card--material-chart"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template v-slot:heading>
      <div
        class="chartjs-wrapper col-lg-12"
        style="text-align: center; "
      >
        <canvas
          ref="chartjsgauge"
          class="chartjs-gauge"
        />
      </div>
    </template>

    <slot
      slot="reveal-actions"
      name="reveal-actions"
    />

    <slot>
      <h4 class="card-title font-weight-light mt-2 ml-2">
        Importo Fatturato
      </h4>
      <p class="d-inline-flex font-weight-light ml-2 mt-1">
        Indice Budget {{ annocorrente }}
      </p>
    </slot>

    <slot
      slot="actions"
      name="actions"
    />
  </base-material-card>
</template>

<script>
/**
 *  da considerare anche https://github.com/amroessam/vgauge
 *  http://bernii.github.io/gauge.js/
 */
  import Chart from 'chartjs-gauge'
  export default {
    name: 'TrgGaugetChart',
    props: {
      fatturato: {
        type: Number,
        default: 0,
      },
      annocorrente: {
        type: Number,
        default: 2020,
      },
      // The chart's data.labels
      labels: {
        type: Array,
        default: null,
      },

      // The chart's data.datasets
      datasets: {
        type: Array,
        required: true,
      },
    },
    data () {
      return {
        chart: null,
      }
    },
    watch: {
      datasets (newDatasets) {
        // Replace the datasets and call the update() method on Chart.js
        // instance to re-render the chart.
        console.log('upd ', newDatasets)
        this.chart.data.datasets = newDatasets
        this.chart.update()
      },
    },
    mounted () {
      // const ctx = document.getElementById('chartjs-gauge').getContext('2d')
      this.chart = new Chart(this.$refs.chartjsgauge, {
        type: 'gauge',
        data: {
          datasets: this.datasets,
        },
        options: {
          cutoutPercentage: 80,
          needle: {
            radiusPercentage: 3,
            widthPercentage: 3.2,
            lengthPercentage: 80,
            color: 'rgba(255, 255, 255, 0.9)',
          },
          valueLabel: {
            display: true,
            fontSize: 18,
            formatter: (value) => {
              // return '$' + Math.round(value)
              return Number(value).toLocaleString('it-IT', { minimumFractionDigits: 2 }) + ' €'
            },
            color: '#000',
            backgroundColor: 'transparent',
            borderColor: '#F00',
            borderRadius: 5,
            padding: {
              top: 5,
              bottom: 10,
            },
          },
        },
      })
    },
    beforeDestroy () {
      // Don't forget to destroy the Chart.js instance.
      if (this.chart) {
        this.chart.destroy()
      }
    },

  }
</script>

<style lang="sass">
  .v-card--material-chart
    p
      color: #999

    .v-card--material__heading
      max-height: none

      .ct-label
        color: inherit
        opacity: .7
        font-size: 0.975rem
        font-weight: 100

      .ct-grid
        stroke: rgba(255, 255, 255, 0.2)

      .ct-series-a .ct-point,
      .ct-series-a .ct-line,
      .ct-series-a .ct-bar,
      .ct-series-a .ct-slice-donut
          stroke: rgba(255,255,255,.8)

      .ct-series-a .ct-slice-pie,
      .ct-series-a .ct-area
          fill: rgba(255,255,255,.4)
</style>
