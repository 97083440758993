import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'

const vuexLocalStorage = new VuexPersistence({
  storage: window.localStorage,
  reducer: (state) => ({
    userinfo: state.userinfo,
    clidata: state.clidata,
  }),
})

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [vuexLocalStorage.plugin],
  state: {
    barColor: 'rgba(0, 0, 0, .8), rgba(0, 0, 0, .8)',
    barImage: 'https://demos.creative-tim.com/material-dashboard/assets/img/sidebar-1.jpg',
    drawer: null,
    userinfo: null,
    clidata: null,
  },
  getters: {
    isAuthenticated: function (state) {
        return state.userinfo != null
    },
    cliData: function (state) {
      return state.clidata
    },
  },
  mutations: {
    SET_BAR_IMAGE (state, payload) {
      state.barImage = payload
    },
    SET_DRAWER (state, payload) {
      state.drawer = payload
    },
    storeUserInfoMutation: function (state, data) {
      console.log('mutations storeUserInformations')
      console.log(data)
      state.userinfo = data
      console.log('adesso lo stato vale ', state.userinfo)
    },
    storeCliDataMutation: function (state, data) {
      state.clidata = data
    },
    logoutMutation: function (state) {
      // localStorage.clear()
    },
  },
  actions: {
    storeUserInfo: function ({ commit }, data) {
      commit('storeUserInfoMutation', data)
    },
    storeCliData: function ({ commit }, data) {
      commit('storeCliDataMutation', data)
    },
    logout: function ({ commit }) {
      commit('logoutMutation')
    },
  },
})
